/* banner.css */
.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  height: 100vh;
  background: linear-gradient(to bottom, #F4F8FE, #FFFFFF, #FFFFFF);
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/image/fondo-celulares2.PNG');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.3; 
  z-index: 0;
}

.banner-content{
  max-width: 100%;
  text-align: center;
  z-index: 1; 
}

.banner-content h1 {
  background: linear-gradient(to right, #EB0002, #A87F3F);
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 1rem;
}

.banner-content p {
  font-size: 16px;
  color: #73828F;
  margin-bottom: 1rem;
}

.banner-content button {
  padding: 8px 16px;
  font-size: 14px;
}

.banner-content a {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #EB0002;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  text-decoration: none;
}

.banner-images {
  display: none;    
}

/* Estilos para tabletas y pantallas medianas (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .banner::before {
    display: none; 
  }

  .banner {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
  }

  .banner-content {
    max-width: 60%; /* El texto ocupa 30% del ancho */
    text-align: left;
    padding: 2rem;
  }

  .banner-content h1{
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .banner-content p{
    margin-bottom: 2rem;
  }

  .banner-images {
    display: flex;
    width: 40%; /* Las imágenes ocupan el 65% */
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .my-masonry-grid{
    
  }

  .my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
  }

  .my-masonry-grid_column > .image-container {
    margin-bottom: 20px;
  }

  .image-container img {
    width: 100%;
    border-radius: 32px;
    object-fit: cover;
  }

  /* Animacion */
  .my-masonry-grid {
    display: flex;
    flex-wrap: nowrap;
    animation: moveUpDown 10s linear infinite;
  }

  .my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
  }

  .my-masonry-grid_column > .image-container {
    margin-bottom: 20px;
  }

  .image-container img {
    width: 100%;
    border-radius: 32px;
    object-fit: cover;
  }

  /* Definir la animación */
  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50%);
    }
    100% {
      transform: translateY(0);
    }
  }
}

/* Estilos para pantallas grandes (1024px - 1280px) */
@media (min-width: 1024px) {
  .banner::before {
    display: none; 
  }

  .banner {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
  }

  .banner-content {
    max-width: 50%; /* El texto ocupa 30% del ancho */
    text-align: left;
    padding: 5rem;
  }

  .banner-content h1{
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .banner-content p{
    margin-bottom: 2rem;
  }

  .banner-images {
    display: flex;
    width: 50%; /* Las imágenes ocupan el 65% */
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
  }

  .my-masonry-grid_column > .image-container {
    margin-bottom: 20px;
  }

  .image-container img {
    width: 100%;
    border-radius: 32px;
    object-fit: cover;
  }

  /* Animacion */
  .my-masonry-grid {
    display: flex;
    flex-wrap: nowrap;
    animation: moveUpDown 10s linear infinite;
  }

  .my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
  }

  .my-masonry-grid_column > .image-container {
    margin-bottom: 20px;
  }

  .image-container img {
    width: 100%;
    border-radius: 32px;
    object-fit: cover;
  }

  /* Definir la animación */
  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50%);
    }
    100% {
      transform: translateY(0);
    }
  }
}

/*.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  max-height: 800px; 
  overflow: hidden;
  height: 100vh;
}

.banner-content {
  width: 40%;
  text-align: left;
}

.banner-content h1 {
  font-size: 36px;
  background: linear-gradient(to right, rgba(177, 24, 48, 1), rgba(255, 122, 0, 1));
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-content p {
  font-size: 18px;
  color: #555;
  margin: 20px 0;
}

.banner-content a {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: rgba(177, 24, 48, 1);
  border: none;
  cursor: pointer;
  border-radius: 8px;
  text-decoration: none;
}

.banner-images{
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

/*.banner-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 800px;
  overflow: hidden;
  justify-content: flex-end;
  width: 100%;
}*/

/*.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.column img {
  width: 100%;
  border-radius: 8px;
  max-width: 220px;
  max-height: 380px;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.my-masonry-grid_column > .image-container {
  margin-bottom: 30px;
}

.banner-images img {
  border-radius: 8px;
  width: 100%;
}

@media (max-width: 1440px) {
  .banner-content h1 {
    font-size: 32px;
  }
  .banner-content p {
      margin-right: 40px;
  }

}

@media (max-width: 834px) {
  .banner {
    flex-direction: row;
    align-items: center;
    max-height: 700px;
    justify-content: space-between;
  }

  .banner-content {
    max-width: 50%;
    margin-bottom: 20px;
    align-items: center;
  }

  .banner-images {
    max-width: 47%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .column {
    width: 48%; 
    display: flex;
    flex-direction: column;
  }

  /* Ocultar la tercera columna en tabletas 
  .column:nth-child(3) {
    display: none;
  }
  .banner-content p {
    margin-right: 0px;
  }

}

@media (max-width: 393px) {
  .banner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    z-index: 0;
    overflow: hidden;
  }

  .banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/image/fondo-celulares2.PNG');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3; /* Ajusta la opacidad solo para la imagen 
    z-index: 0; /* Mantén el fondo detrás del contenido 
  }

  .banner-content{
    max-width: 100%;
    text-align: center;
    z-index: 1; 
  }
  .banner-content h1 {
    font-size: 24px;
  }

  .banner-content p {
    font-size: 14px;
    margin-right: 0px;
  }

  .banner-content button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .banner-images {
    display: none;    
  }
}*/