.contenedor{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    z-index: 0;
    overflow: hidden;
    height: 100vh;
    background: linear-gradient(to bottom, #F4F8FE, #FFFFFF, #FFFFFF);
}

.login a{
    text-decoration: none;
    color: rgba(177, 24, 48, 1);
    font-size: 1rem;
    display: none;
}

.error-message {
    font-size: 14px;
    padding: 5px;
    max-width: 100%;
  }

.contenedor::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/image/fondo-celulares2.PNG');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3; 
    z-index: 0;
}

.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    max-width: 100%;
    text-align: center;
    z-index: 1;
    padding: 2rem;
}

.login .formulario{
    font-size: 1.4rem;
}

.login .formulario label{
    font-weight: 600;
}

.login .formulario input{
    width: 300px; 
    height: 40px;
    border: 1px solid #080808;
}

.logo {
    margin-bottom: 20px; 
}

.logo img {
    width: 200px;
    height: auto;
}


.ingresar{
    background-color: #EB0002;
    color: #fff;
    padding: .5rem 1.5rem .5rem 1.5rem;
}

.ingresar:hover{
    background-color: #A87F3F;
    color: #fff;
}

